.hero-slider {
  &--center-white {
    color: $white;
    text-align: center; } }

.hero-slide {
  $this: &;
  color: $white;
  position: relative;
  height: auto;
  &__bg {
    @include coverdiv;
    @include cover;
    background-color: $gray;
    z-index: 1;
    overflow: hidden;
    display: none;
    @include before {
      @include coverdiv;
      z-index: 0;
      background-color: rgba($black, .4);
      @include xsmin {
        display: none; } }
    #{$this}.swiper-slide-active & {
      display: block; }
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__inner {
    padding-top: 15%;
    padding-bottom: 15%;
    position: relative;
    z-index: 1;
    @include xs {
      padding-top: rem(60);
      padding-bottom: rem(90); } }
  &__subttl {
    font-weight: 700;
    margin-bottom: rem(25); }
  &__title {
    margin-bottom: rem(15); } }

.hero {
  $this: &;
  @include cover;
  position: relative;
  @include before {
    @include coverdiv;
    background-color: rgba($black, .4);
    z-index: 0; }
  &__breadcrumbs {
    margin-bottom: rem(75);
    position: relative;
    z-index: 1;
    @include xs {
      margin-bottom: rem(60); } }
  &__title {
    text-align: center;
    position: relative;
    z-index: 1; }
  &--sm {
    color: $white;
    padding-top: rem(15);
    padding-bottom: rem(115);
    @include xs {
      padding-bottom: rem(100); } } }
