.s-man-info-items {
  position: relative;
  @include lgmin {
    padding-top: rem(95);
    background-color: $gray-lightest; }
  &__img {
    @include flex(flex-end, center, column);
    @include lg {
      display: none; } }
  &__item {
    @include xs {
      margin-bottom: rem(15);
      height: auto; } } }

.s-call {
  @include cover;
  margin-top: rem(65);
  color: $white;
  background-position: 70% 50%;
  @include lg {
    margin-top: 0; }
  @include md {
    background-position: 80% 50%; }
  &__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(-65);
    @include lg {
      display: none; } }
  &__content {
    padding-top: rem(70);
    padding-bottom: rem(70); }
  &__form {
    max-width: 230px; } }

.s-thanks {
  position: relative;
  &__block {
    @include lgmin {
      text-align: center; } }
  &__icon {
    @include lg {
      margin-left: 0;
      margin-right: 0; } }
  &__img {
    position: absolute;
    z-index: 0;
    bottom: rem(-20);
    right: 10%;
    @include xxl {
      right: 0; }
    @include lg {
      left: rem(25);
      right: auto; }
    @include md {
      width: 60%; }
    @include sm {
      width: 50%; }
    @include xxs {
      display: none; } } }

.s-gallery {
  &__right {
    @include flex(flex-start, normal, column);
    height: 100%; }
  &__text, &__title {
    max-width: 625px;
    margin-left: auto;
    margin-right: auto; }
  &__text {
    flex-grow: 1;
    margin-bottom: rem(30);
    @include mdmin {
      text-align: center; } }
  &__title {
    margin-bottom: rem(30);
    @include mdmin {
      text-align: center; } }
  &__info-item {
    @include md {
      margin-bottom: rem(30);
      height: auto; } } }

.s-two-cols {
  position: relative;
  @include md {
    @include flex(flex-start, normal, column-reverse); }
  &__left {
    background-color: $gray;
    @include cover;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    top: 0;
    width: 50%;
    @include before {
      @include arr(#{rem(45)}, 6.25rem, $accent, l);
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 0;
      transform: translate(0, -50%); }
    @include md {
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      padding-top: 60%;
      width: 100%;
      @include before {
        @include arr(6.25rem, #{rem(45)}, $accent, t);
        top: auto;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0); } } }
  &__right {
    background-color: $accent;
    padding-top: rem(130);
    padding-bottom: rem(130);
    @include md {
      padding-top: rem(60);
      padding-bottom: rem(60); } } }


.s-item {
  border-top: 1px solid $gray-lightest;
  border-bottom: 1px solid $gray-lightest;
  &__img {
    margin-top: rem(20);
    margin-bottom: rem(20);
    @include md {
      display: none; } }
  &__content {
    border-right: 1px solid $gray-lightest;
    border-left: 1px solid $gray-lightest;
    padding: rem(35);
    @include lg {
      padding: rem(35) rem(15); }
    @include md {
      border: 0;
      padding-left: 0;
      padding-right: 0; } } }

.s-tyres {
  @include md {
    padding-top: 0; }
  &__filters {
    margin-left: auto;
    margin-right: auto;
    max-width: 1010px;
    @include md {
      margin-left: rem(-15);
      margin-right: rem(-15);
      border-left: 0;
      border-right: 0;
      border-top: 0; } } }

.s-error {
  background-position: 70% 50%;
  &__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include xxs {
      display: none; } } }

.s-contacts {
  @include md {
    padding-bottom: 0; }
  &__map {
    @include md {
      margin-left: rem(-15);
      margin-right: rem(-15); } } }

.s-form {
  position: relative;
  &__left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: percentage(1200 / 1920);
    @include flex(center, normmal, column);
    @include lg {
      width: 50%; }
    @include md {
      position: static;
      width: 100%; } }
  &__right {
    padding-top: rem(170);
    padding-bottom: rem(170);
    @include md {
      padding-top: rem(30);
      padding-bottom: rem(75); } }
  &__form {
    @include md {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto; } } }

.s-some-info {
  color: $white;
  background-color: #545454;
  @include cover;
  background-position: 60% 100%;
  @include md {
    background-position: 45% 100%; }
  @include sm {
    background-size: 200%; }
  @include xxs {
    background-size: 250%; }
  @include r(374) {
    background-image: none !important; }
  &__left {
    height: 100%;
    @include flex(flex-start, flex-end);
    @include sm {
      padding-top: rem(200); }
    @include xxs {
      padding-top: rem(100); }
    @include r(374) {
      padding-top: 0; } } }

.s-car-list {
  @include md {
    background-color: $gray-lightest; } }

