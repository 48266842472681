.s-text {
  max-height: 360px;
  overflow: auto;
  h1 {
    @extend .title;
    @extend .title--h5;
    margin-bottom: rem(5); }
  p {
    @include notlast {
      margin-bottom: rem(25); } } }
