.grid {
  &--row-reverse {
    flex-direction: row-reverse; }
  &--align-items-center {
    align-items: center; }
  &--align-items-end {
    align-items: flex-end; }
  &--justify-content-center {
    justify-content: center; }
  &--justify-content-end {
    justify-content: flex-end; }
  &--justify-content-space-around {
    justify-content: space-around; }
  &--justify-content-space-between {
    justify-content: space-between; } }
