.article {
  h2 {
    @extend .title;
    @extend .title--h3; }
  h3 {
    @extend .title;
    @extend .title--h5; }
  p, ul, ol {
    @include notlast {
      margin-bottom: rem(25); } }
  ul {
    li {
      @include list-bullet;
      &::before {
        display: inline-block;
        color: $accent;
        margin-left: .3em; } } }
  ol {
    @include counter('text', ')');
    li {
      &::before {
        display: inline-block;
        margin-left: .3em; } } }
  a {
    &:not(.btn) {
      color: $blue;
      text-decoration: underline; } } }

