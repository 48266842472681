.section {
  $this: &;
  padding-top: rem(95);
  padding-bottom: rem(95);
  @include lg {
    padding-top: rem(70);
    padding-bottom: rem(70); }
  @include md {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem; }
  &__title {
    margin-bottom: rem(30);
    &:not(#{$this}__title--side) {
      text-align: center; }
    &--short {
      max-width: 810px;
      margin-left: auto;
      margin-right: auto; } }
  &__subttl {
    margin-bottom: rem(30);
    @include md {
      margin-bottom: rem(15); } }
  &__btn {
    text-align: center;
    margin-top: rem(50); }
  &__block {
    margin-bottom: rem(30); }
  &--gray {
    background-color: $gray-lightest; }
  &--white {
    color: $white; }
  &--lg {
    padding-top: rem(130);
    padding-bottom: rem(140); }
  &--bg {
    background-color: $gray;
    @include cover; } }

.section-full {
  @include cover;
  background-color: $c-text;
  &__inner {
    @include flex(flex-start, normal, column);
    padding-top: rem(15);
    position: relative;
    z-index: 1; }
  &__content {
    padding-top: rem(140);
    padding-bottom: rem(140);
    flex-grow: 1;
    @include flex(center, normal, column);
    position: relative;
    z-index: 1;
    @include sm {
      padding-top: rem(60);
      padding-bottom: rem(75); } }
  &__icon {
    width: 1em;
    height: 1em;
    font-size: rem(60);
    border-radius: 50%;
    border: 1px solid currentColor;
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(15);
    @include lg {
      font-size: rem(40); }
    @include sm {
      font-size: rem(35); }
    .icon {
      font-size: .4em; } } }
