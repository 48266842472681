.member {
  @include flex(flex-start, normal, column);
  text-align: center;
  height: auto;
  &__img {
    background-color: $gray;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    padding-top: percentage(280 / 360); }
  &__content {
    flex-grow: 1;
    background-color: $white;
    padding: rem(20); }
  &__title {
    margin-bottom: rem(10); } }
