.btn {
  $this: &;
  display: inline-block;
  background-color: $accent;
  border: 1px solid $accent;
  // color: $white
  font-weight: 700;
  font-size: em(18);
  min-width: 230px;
  padding: .5em 1em;
  text-align: center;
  @include tr(color .3s, background-color .3s, border-color .3s);
  @include hover {
    &:not(#{$this}--no-hover) {
      background-color: transparent;
      color: $accent; } }

  // +xs
  //   min-width: 0
  //   width: 100%

  &--dark {
    background-color: $c-text;
    border-color: $c-text;
    color: $white;
    @include hover {
      &:not(#{$this}--no-hover) {
        color: $c-text; } } }
  &--dark-text {
    color: $c-text; }
  &--transp {
    background-color: transparent;
    color: $accent;
    @include hover {
      &:not(#{$this}--no-hover) {
        color: $c-text;
        background-color: $accent; } } }
  &--white {
    background-color: $white;
    border-color: $white;
    @include hover {
      &:not(#{$this}--no-hover) {
        color: $white; } } }
  &--sm {
    padding: .3em 1em;
    height: rem(38); }
  &--full {
    min-width: 0;
    width: 100%; } }
