.block-links {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  border: 1px solid  $gray-lightest;
  padding: rem(55);
  @include lg {
    padding-left: rem(20);
    padding-right: rem(20); }
  &__link {
    display: block;
    margin-bottom: rem(30);
    flex-grow: 1; }
  &__title {}
  &__btn {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 230px; } }
