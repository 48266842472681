.footer {
  position: relative;
  @include lg {
    padding-top: rem(150); }
  &__block, &__left {
    padding-top: rem(50);
    padding-bottom: rem(50); }
  &__title {
    margin-bottom: rem(15);
    position: relative;
    padding-bottom: rem(8);
    @include before {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      width: rem(45);
      height: 1px;
      background-color: $accent; } }
  &__block {
    @include lg {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: rem(30); } }
  &__left {
    border-right: 1px solid  $gray-light;
    height: 100%;
    text-align: left;
    @include lg {
      text-align: right;
      padding-top: 0;
      border-right: 0;
      @include flex(flex-start, normal, column-reverse); } }
  &__logo {
    margin-bottom: rem(20);
    @include lg {
      position: absolute;
      z-index: 1;
      right: rem(15);
      top: rem(70); } }
  &__copy {
    margin-bottom: rem(30);
    font-size: em(14);
    color: #9d9d9d;
    @include lg {
      margin-bottom: 0; } }
  &__btn {
    @include lg {
      margin-bottom: rem(15); } } }
