@for $i from 1 through $cols-number {
  .col {
    &--offset-#{$i} {
      margin-right: percentage($i/$cols-number); }
    &--offset-xxs-#{$i} {
      @include xxsmin {
        margin-right: percentage($i/$cols-number); } }
    &--offset-xs-#{$i} {
      @include xsmin {
        margin-right: percentage($i/$cols-number); } }
    &--offset-sm-#{$i} {
      @include smmin {
        margin-right: percentage($i/$cols-number); } }
    &--offset-md-#{$i} {
      @include mdmin {
        margin-right: percentage($i/$cols-number); } }
    &--offset-lg-#{$i} {
      @include lgmin {
        margin-right: percentage($i/$cols-number); } }
    &--offset-xl-#{$i} {
      @include xlmin {
        margin-right: percentage($i/$cols-number); } } } }
