@include font('rubik', 'rubik-light-webfont', 300);
@include font('rubik', 'rubik-regular-webfont', 400);
// +font('rubik', 'rubik-medium-webfont', 500)
@include font('rubik', 'rubik-bold-webfont', 700);
// +font('rubik', 'rubik-black-webfont', 900)

@include font('rubik', 'rubik-lightitalic-webfont', 300, italic);
// +font('rubik', 'rubik-italic-webfont', 400, italic)
// +font('rubik', 'rubik-mediumitalic-webfont', 500, italic)
// +font('rubik', 'rubik-bolditalic-webfont', 700, italic)
// +font('rubik', 'rubik-blackitalic-webfont', 900, italic)

@mixin reg {
  font-family: 'rubik', sans-serif; }

