.nav {
  @include lg {
    background-color: $c-text;
    color: $white; }
  li {
    display: inline-block;
    position: relative;
    @include lg {
      display: block;
      border-bottom: 1px solid  #5d5d5d;
      padding-top: .7rem;
      padding-bottom: .7rem;
      padding-left: rem(25);
      padding-right: rem(25); }
    @include notlast {
      margin-left: 1rem;
      @include lg {
        margin-left: 0; } }
    > ul {
      background-color: #2e2e2e;
      color: $white;
      margin-top: .7rem;
      margin-bottom: -.7rem;
      margin-left: rem(-25);
      margin-right: rem(-25);
      padding-left: rem(25);
      padding-right: rem(25);
      overflow: hidden;
      max-height: 0;
      border-top: 0;
      @include lgmin {
        @include hidden;
        color: $c-text;
        position: absolute;
        z-index: 5;
        background-color: $gray-light;
        top: 100%;
        width: 200px;
        border: 0;
        margin: 0;
        max-height: none;
        overflow: visible; }
      li {
        display: block;
        padding: .7rem;
        @include notlast {
          margin-left: 0; }
        @include lg {
          border: 0; } } }
    &:hover {
      > ul {
        border-top: 1px solid  #5d5d5d;
        max-height: none;
        overflow: visible;
        @include lgmin {
          @include visible;
          border-top: 0; } } } }


  a {
    @include tr(color .3s);
    @include hover {
      color: $accent; } }
  &__current-item {
    color: $accent; }
  &__item-with-submenu {
    @include before {
      @include arr(.5em, .25em, currentColor, b);
      display: inline-block;
      margin-left: .3em;
      position: relative;
      top: -.15em; }
    &:hover {
      @include before {
        @include arr(.5em, .25em, currentColor, t); } } }

  &--footer {
    @include lg {
      background-color: transparent;
      color: inherit; }
    li {
      display: block;
      @include notlast {
        margin-right: 0;
        margin-bottom: rem(12); }
      @include lg {
        border: 0;
        padding: 0; } } } }
