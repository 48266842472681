.contacts {
  &__block {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    @include notlast {
      margin-bottom: rem(25); } } }

.contacts-block {
  padding-bottom: rem(20);
  position: relative;
  text-align: center;
  @include after {
    width: rem(45);
    height: 1px;
    background-color: $accent;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0); }
  &__icon {
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(15);
    width: 1em;
    height: 1em;
    font-size: rem(40);
    background-color: $accent;
    border-radius: 50%;
    .icon {
      font-size: rem(25); } }
  &__title {
    margin-bottom: rem(10); } }

.map {
  height: 100%;
  position: relative;
  background-color: $gray;
  padding-top: 80%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; } }
