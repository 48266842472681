@mixin scrollbar($background: $white) {
  &::-webkit-scrollbar {
    width: rem(5); }
  &::-webkit-scrollbar-track {
    background-color: $gray-light;
    box-shadow: inset #{rem(2)} 0 0px $background, inset -#{rem(2)} 0 0px $background;
    outline: none; }
  &::-webkit-scrollbar-thumb {
    background-color: $accent;
    @include tr(background-color .3s); } }

.scrollbar {
  overflow: auto;
  padding-left: rem(30);
  @include scrollbar; }

.simplebar-scrollbar {
  width: 100%;
  z-index: 1;
  &::before {
    opacity: 1 !important;
    border-radius: 0;
    background-color: $accent;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 1; } }

.simplebar-track.simplebar-vertical {
  width: rem(5);
  right: auto;
  left: 0;
  @include after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: $gray-light;
    width: rem(1);
    left: 50%;
    transform: translate(-50%, 0); } }
