.banner {
  @include cover;
  height: 100%;
  @include flex(flex-start, center);
  padding: rem(55);
  @include md {
    padding: rem(75) rem(15) rem(75);
    margin-left: rem(-15);
    margin-right: rem(-15); }
  &__title {
    margin-bottom: rem(25); }
  &__content {
    max-width: 240px; } }
