small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.strong {
  font-weight: 700; }

.title {
  display: block;
  font-weight: 700;
  font-size: em(42);
  line-height: 1;
  &--h2 {
    font-size: em(36); }
  &--h3 {
    font-size: em(21); }
  &--h5 {
    font-size: 1em; }
  &--xxl {
    font-size: em(150);
    @include lg {
      font-size: em(120); }
    @include md {
      font-size: em(100); }
    @include sm {
      font-size: em(90); }
    @include xs {
      font-size: em(80); } } }

.title-under {
  position: relative;
  padding-bottom: .5em;
  @include before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $accent;
    width: rem(80);
    height: 1px; } }
