.item-descr {
  &__top {
    @include md {
      @include flex(flex-start, center);
      padding-bottom: rem(8); } }
  &__img {
    width: 120px;
    margin-left: rem(20);
    @include mdmin {
      display: none; }
    @include xxs {
      width: 80px; } }
  &__title {
    color: $blue;
    text-decoration: underline;
    font-size: em(21);
    margin-bottom: rem(15); }
  &__price-block {
    margin-bottom: rem(12); }
  &__meta, &__text {
    @include md {
      margin-left: rem(-15);
      margin-right: rem(-15);
      padding-left: rem(15);
      padding-right: rem(15); } }
  &__meta {
    max-width: 540px;
    margin-bottom: rem(10); }
  &__text {
    max-width: 565px;
    position: relative;
    padding-bottom: rem(12);
    margin-bottom: rem(25);
    @include before {
      background-color: $gray-lightest;
      height: 1px;
      width: 100%;
      max-width: 540px;
      bottom: 0;
      right: 0;
      position: absolute; } }
  &__btn {
    margin-bottom: rem(25); } }

.item-meta {
  @include flex();
  border-top: 1px solid  $gray-lightest;
  border-bottom: 1px solid  $gray-lightest;
  padding-top: rem(12);
  padding-bottom: rem(12);
  &__item {
    @include notfirst {
      padding-right: rem(15);
      border-right: 1px solid $gray-lightest; }
    @include notlast {
      padding-left: rem(15); } } }

.item-del-meta {
  --padding: #{rem(15)};
  @include flex(flex-start, center);
  @include lg {
    --padding: #{rem(10)}; }
  @include sm {
    font-size: em(14); }
  @include xxs {
    display: block; }
  &__item {
    color: $gray;
    @include flex(flex-start, center);
    @include notfirst {
      padding-right: rem(15);
      padding-right: var(--padding);
      border-right: 1px solid currentColor; }
    @include notlast {
      padding-left: rem(15);
      padding-left: var(--padding); }
    @include xxs {
      border-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: rem(10); } }
  &__icon {
    font-size: 0;
    margin-left: rem(10);
    .icon {
      font-size: rem(30); } }
  &__text {
 } }    // max-width: 120px
