$fz:       16px;
$fz-sm-px: 14px;
$fz-xs-px: 12px;
$fz-sm:    em(14);
$fz-xs:    em(12);

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']", "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']", "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']", "input:not([type])", "textarea";
$check-inputs: "[type='checkbox']", "[type='radio']";

// colors
$c-text:        #3a3a3a;

$black:         #000;
$white:         #fff;

$accent:        #ffb32a;

$gray:          #636363;
$gray-light:    #e3e3e3;
$gray-lightest: #f3f3f3;

$blue:          #1678e5;

@mixin windowHeight($offset: 0px) {
  height: calc(100vh - #{$offset});
  height: calc((var(--vh, 1vh) * 100) - #{$offset}); }

// header heights
$header-top-height:       rem(43);
$header-bottom-height:    rem(100);
$header-bottom-height-lg: rem(55);

$header-height:           $header-top-height + $header-bottom-height;
$header-height-lg:        $header-bottom-height-lg;
