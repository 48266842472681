.breadcrumbs {
  li {
    display: inline-block; }
  a {
    @include tr(color .3s);
    @include hover {
      color: $accent; } }

  &__current-item {
    color: #9b9b9b; } }
