.header {
  $this: &;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  &__top {
    background-color: $white;
    @include lg {
      display: none; } }

  &__bottom {
    background-color: $gray-lightest; }
  &__inner {
    @include flex(flex-start, center);
    padding-top: rem(8);
    padding-bottom: rem(8);
    #{$this}__top & {
      height: $header-top-height; }
    #{$this}__bottom & {
      height: $header-bottom-height;
      @include lg {
        height: $header-bottom-height-lg; } } }
  &__nav {
    flex-grow: 1; }
  &__nav-wrap {
    flex-grow: 1;
    @include lg {
      position: absolute;
      z-index: 10;
      background-color: $white;
      top: 100%;
      max-height: calc(100vh - #{$header-height-lg});
      overflow: auto;
      right: 0;
      left: 0;
      @include hidden;
      @include ready {
        @include tr(opacity .3s, visibility .3s); }

      &--open {
        @include visible; } } }
  &__contacts {
    @include flex(flex-start, center);
    flex-grow: 1;
    @include lg {
      display: block;
      margin-bottom: rem(25); } }
  &__contact {
    @include notlast {
      margin-left: rem(100);
      @include xxl {
        margin-left: rem(50); }
      @include lg {
        margin-left: 0;
        margin-bottom: rem(25); } }
    &--hidden-mob {
      @include lg {
        display: none !important; } } }
  &__social {
    @include flex(flex-start, center);
    margin-left: rem(15);
    color: $gray;
    @include lg {
      margin-left: 0; } }
  &__logos-mob-wrap {
    @include flex(flex-start, center); }
  &__logos {
    font-size: 0;
    @include lg {
      margin-left: rem(15); } }

  &__logo-sm {
    display: inline-block;
    vertical-align: middle;
    @include notlast {
      margin-left: rem(14); } }
  &__logo {
    margin-right: rem(25);
    @include lg {
      width: 125px;
      margin-right: rem(15); } }
  &__burger {
    flex-grow: 1;
    @include lgmin {
      display: none; } }
  &__img {
    @include lg {
      width: 42px; } }
  &__img-link {
    font-size: 0;
    display: inline-block; }
  &__meta-mob {
    padding: rem(15);
    @include lgmin {
      display: none; } } }


.header-contact {
  $this: &;
  @include flex(flex-start, center);
  position: relative;
  @include lg {
    &:not(#{$this}--content) {
      display: block;
      padding-right: rem(37); } }
  &__text {
    @include notlast {
      margin-left: em(10); }
    &--front {
      @include lg {
        display: none; } } }
  &__link {
    font-weight: 400;
    @include tr(color .3s);
    @include hover {
      color: $accent; } }
  &__icon {
    background-color: $accent;
    width: rem(27);
    height: rem(27);
    margin-left: rem(10);
    flex: 0 0 rem(27);
    border-radius: 50%;
    font-size: 0;
    @include flex(center, center);
    .icon {
      font-size: 1rem; }
    @include lg {
      position: absolute;
      right: 0;
      top: -.15rem; } }

  &--content {
    #{$this}__icon {
      @include lg {
        position: static; } }
    #{$this}__text {
      &--front {
        display: block; } } } }

.burger {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(37);
  height: rem(20);
  position: relative;
  span {
    display: block;
    &, &::after, &::before {
      width: 100%;
      height: rem(4);
      background-color: currentColor;
      backface-visibility: hidden;
      @include tr(transform .3s); }
    &::after, &::before {
      content: "";
      position: absolute;
      left: 0; }
    &::after {
      top: 0; }
    &::before {
      bottom: 0; } }
  &--active {
    @include before {
      @include arr(1.4em, .7em, currentColor, t);
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 50%;
      transform: translate(-50%, rem(6)); } } }
