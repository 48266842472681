.feature {
  $this: &;
  text-align: center;
  &__icon {
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    color: $accent;
    width: 1em;
    height: 1em;
    font-size: rem(78);
    border-radius: 50%;
    border: 1px solid currentColor;
    margin-bottom: rem(15);
    .icon {
      font-size: rem(45); } }
  &__title {
    margin-bottom: rem(5); }
  &__text {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto; } }

