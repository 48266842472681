.pagination {
  @include flex(center, center);
  li {
    margin-left: rem(2);
    margin-right: rem(2); }
  a {
    @include flex(center, center);
    border: 1px solid  $gray-light;
    height: rem(30);
    min-width: rem(30);
    @include tr(color .3s, background-color .3s);
    @include hover {
      color: $white;
      background-color: $accent; } }
  .prev, .next {
    border: 0;
    @include flex(center, center);
    @include before {
      @include icon-arrow(.5, .5, .1, currentColor, 45, em);
      display: block; } }
  .prev {
    transform: scaleX(-1); } }
