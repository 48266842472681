.descr-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid  $gray-light;
  height: 100%;
  &__img {
    @include cover;
    background-color: $gray;
    padding-top: percentage(275 / 485); }
  &__title {
    margin-bottom: rem(15); }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: rem(25) rem(20); }
  &__text {
    flex-grow: 1;
    margin-bottom: rem(15); } }
