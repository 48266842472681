html {
  font-size: $fz;
  font-weight: 300;
  line-height: 1.4;
  color: $c-text;
  @include reg; }

body {
  background-color: $white;
  min-width: 320px;
  @include no-scroll; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;
  &--loaded:not(#{$this}--no-fade-in) {
    animation: fadeIn 1s; } }


.out {
  padding-top: $header-height;
  @include lg {
    padding-top: $header-height-lg; }
  &__call {
    position: fixed;
    z-index: 10;
    bottom: rem(20);
    left: rem(20);
    @include lgmin {
      display: none; } } }

.call {
  background-color: $accent;
  height: 1em;
  width: 1em;
  font-size: rem(80);
  border-radius: 50%;
  @include flex(center, center);
  .icon {
    font-size: rem(42); } }

.main {
  &__breadcrumbs {
    padding-top: rem(20);
    padding-bottom: rem(20); } }

.logo {
  display: inline-block;
  font-size: 0; }

.card-label {
  display: block;
  text-align: center;
  &__title {
    display: block; }
  &__label {
    display: block; }
  &__subttl {
    display: block;
    font-size: em(14);
    line-height: 1.2;
    font-weight: 400;
    color: #b27200; } }

.corner-label {
  @include flex(center, center);
  background-color: $accent;
  padding: .1rem 1rem;
  height: 1.875rem;
  position: relative;
  font-weight: 400;
  @include before {
    @include arr(1.875rem, 1.875rem, $accent, b);
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 0;
    transform: translate(50%, 0); }
  &__title {
    position: relative;
    z-index: 1; } }

.price-block {
  $this: &;
  display: block;
  &__title {
    display: block;
    color: #fd3c00; }
  &__price {
    display: block; }
  &__subttl {
    display: block; }
  &--flex {
    @include flex(flex-start, baseline);
    #{$this}__title, #{$this}__price {
      margin-left: rem(8); } } }

.price {
  color: #fd3c00;
  font-size: em(24);
  font-weight: 700;
  &--old {
    color: #a9a9a9;
    font-size: 1em;
    font-weight: 300;
    text-decoration: line-through; } }

.phone-label {
  position: relative;
  padding: rem(5);
  text-align: center;
  @include before {
    @include coverdiv;
    z-index: 0;
    background-color: rgba($accent, .1);
    transform: skew(-15deg); }

  &__inner {
    position: relative;
    z-index: 1;
    justify-content: center !important; } }

.info-rect {
  background-color: $white;
  position: relative;
  min-width: 245px;
  padding: rem(30);
  text-align: center;
  color: $c-text;
  @include before {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid  $accent;
    z-index: 0;
    top: rem(5);
    right: rem(-5); }
  @include after {
    @include coverdiv;
    z-index: 1;
    background-color: $white; }
  &__title, &__text {
    position: relative;
    z-index: 2; }
  &__title {
    position: relative;
    padding-bottom: rem(8);
    margin-bottom: rem(8);
    @include before {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $accent;
      height: 1px;
      width: rem(40); } } }


.close {
  @include icon-close(1em, 2px, currentColor);
  display: block;
  @include tr(color .3s);
  @include hover {
    color: $accent; } }

