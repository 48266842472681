.gallery {
  height: 100%;
  &__slider {
    margin-bottom: rem(10);
    color: $white; }
  &__img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-color: $gray;
    padding-top: percentage(350 / 750); }
  &__thumb {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-color: $gray;
    padding-top: 20%;
    position: relative;
    @include before {
      @include coverdiv;
      background-color: rgba($black, .7); }
    &.swiper-slide-thumb-active {
      @include before {
        background-color: transparent;
        border: 2px solid  $accent; } } } }
