.number-block {
  color: $white;
  text-align: center;
  &__title {
    text-transform: uppercase;
    font-size: em(60);
    font-weight: 700;
    position: relative;
    line-height: 1;
    padding-bottom: .2em;
    margin-bottom: rem(20);
    @include before {
      width: rem(45);
      background-color: $white;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0); }
    @include lg {
      font-size: em(55); }
    @include md {
      font-size: em(50); }
    @include sm {
      font-size: em(42);
      margin-bottom: rem(15); }
    @include xs {
      font-size: em(36); } } }
