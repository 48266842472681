.slider-prev, .slider-next {
  @include inline-flex(center, center);
  width: 1em;
  height: 1em;
  font-size: rem(35);
  border: 1px solid currentColor;
  @include tr(color .3s, background-color .3s);
  @include before {
    @include icon-arrow(.3, .3, .07, currentColor, 45, em);
    display: block;
    margin-left: .1em; }

  @include hover {
    color: $accent; } }

.slider-prev {
  transform: scaleX(-1); }

.slider {
  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; } }
  &__controls {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%,  0);
    width: 100%;
    max-width:  calc(#{$container-max-width} - #{($container-padding-static * 2)}); }
  &__prev, &__next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    &.swiper-button-disabled {
      opacity: .5;
      pointer-events: none; } }
  &__prev {
    right: rem(15);
    transform: translate(0, -50%) scaleX(-1); }
  &__next {
    left: rem(15); }

  &__pagination {
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: rem(30);
    @include xs {
      bottom: rem(20); }
    .swiper-pagination-bullet {
      display: inline-block;
      width: rem(14);
      height: rem(14);
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;
      margin-left: rem(2);
      margin-right: rem(2);
      &.swiper-pagination-bullet-active {
        background-color: $accent; } } }

  &--hero {
    &__wrap {
      .slider {
        &__controls {
          @include xxs {
            display: none; } } } } }

  &--gallery {
    &__wrap {
      .slider {
        &__prev {
          right: rem(20); }
        &__next {
          left: rem(20); } } } }

  &--items {
    &__wrap {
      .slider {
        &__controls, &__prev, &__next {
          position: static;
          transform: translate(0, 0); }
        &__controls {
          margin-top: rem(30);
          text-align: center; }

        &__prev, &__next {
          margin-left: rem(15);
          margin-right: rem(15); }
        &__prev {
          transform: scaleX(-1); } } } } }

