@for $i from 1 through $cols-number {
  .col {
    &--order-#{$i} {
      order: $i; }
    &--order-xxs-#{$i} {
      @include xxsmin {
        order: $i; } }
    &--order-xs-#{$i} {
      @include xsmin {
        order: $i; } }
    &--order-sm-#{$i} {
      @include smmin {
        order: $i; } }
    &--order-md-#{$i} {
      @include mdmin {
        order: $i; } }
    &--order-lg-#{$i} {
      @include lgmin {
        order: $i; } }
    &--order-xl-#{$i} {
      @include xlmin {
        order: $i; } } } }
